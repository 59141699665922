import React from "react";

const logoLight = new URL('../images/logo-light.png?width=128', import.meta.url);
const logoDark = new URL('../images/logo-dark.png?width=128', import.meta.url);
const logoLightWebP = new URL('../images/logo-light.webp?width=128', import.meta.url);
const logoDarkWebP = new URL('../images/logo-dark.webp?width=128', import.meta.url);

const Logo = () => {
    return <picture>
        <source srcSet={logoDarkWebP.toString()} type="image/webp" media="(prefers-color-scheme: dark)" />
        <source srcSet={logoLightWebP.toString()} type="image/webp" />
        <source srcSet={logoDark.toString()} type="image/png" media="(prefers-color-scheme: dark)" />
        <img src={logoLight.toString()} style={{width: '64px'}} width={64} height={64} alt="Lett3r Logo" />
    </picture>
};

export { Logo }
