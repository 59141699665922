import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorPage } from "./pages/ErrorPage";

const App = React.lazy(() => import('./App'));
const BlacklistErrorPage = React.lazy(() => import('./pages/BlacklistErrorPage'));
const PreferencesPage = React.lazy(() => import('./pages/PreferencesPage'));
const RevokePage = React.lazy(() => import('./pages/RevokePage'));
const SurveyThanksPage = React.lazy(() => import('./pages/SurveyThanksPage'));
const ValidatedPage = React.lazy(() => import('./pages/ValidatedPage'));
const ValidationErrorPage = React.lazy(() => import('./pages/ValidationErrorPage'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Suspense><App /></Suspense>,
    errorElement: <ErrorPage />
  },
  {
    path: "/validated",
    element: <Suspense><ValidatedPage /></Suspense>
  },
  {
    path: "/validation-error",
    element: <Suspense><ValidationErrorPage /></Suspense>
  },
  {
    path: "/bl-error",
    element: <Suspense><BlacklistErrorPage /></Suspense>
  },
  {
    path: "/survey/thanks",
    element: <Suspense><SurveyThanksPage /></Suspense>
  },
  {
    path: "/preferences",
    element: <Suspense><PreferencesPage /></Suspense>
  },
  {
    path: "/revoke/*",
    element: <Suspense><RevokePage /></Suspense>
  }
]);

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);