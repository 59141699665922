import React from "react";
import { useRouteError } from "react-router-dom";
import { Logo } from "../components/Logo";

const ErrorPage: React.FC = () => {
  const error = useRouteError() as { statusText?: string, message?: string };
  
  return <section className="intro">
    <header className="intro-header">
      <h1 className="logo"><Logo /> Lett3r</h1>
    </header>
    <section className="page">
      <h2 className="title">Oh no!</h2>
      <p className="description">Sorry, an unexpected error has occurred.</p>
      <p style={{fontWeight: 300}}>{error.statusText || error.message}</p>
    </section>
  </section>;
};
  
export { ErrorPage }
